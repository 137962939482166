<template>
  <VueDocPreview :value="docValue" :type="docType" height="100"/>
</template>

<script>
  import VueDocPreview from 'vue-doc-preview'
  export default {
    name: 'Preview',
    components: {
      VueDocPreview
    },
    data() {
      return {
        docValue: 'http://192.168.1.5:8080/1.docx',
        // docValue: 'newteach.pbworks.com%2Ff%2Fele%2Bnewsletter.docx',
        docType: 'office'
      }
    }
  }
</script>